export default {
	props: {
		text: String,
		isDisabled: {
			type: Boolean,
			default: false
		}
	},
	template: '#click-event-link-template'
};
