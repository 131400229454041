var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "loading-indicator" }, [
    _c("i", { staticClass: "fa fa-lg fa-spinner fa-spin" }),
    _vm._v(" "),
    _c("span", [_vm._t("default", [_vm._v("Loading!")])], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }