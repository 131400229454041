<template>
	<div  v-if="siteEditModeIsOn" class="editable-field" @click.prevent.stop="turnOnEditMode($event)" :class="{'edit-mode-on': showEditModeClass, 'pointer': userCanEdit }">
		<div class="editable-field-display-mode" v-if="!editing">
			<slot name="edit-mode-only" v-if="hasSpecialEditModeDisplay"></slot>
			<slot v-else></slot>
			<transition name="fade" class="editable-field-confirmation-check">
				<i class="fa fa-fw fa-check fa-lg green" title="Success" aria-label="Success" rel="#" style="" v-if="confirmationIconShowing"></i>
			</transition>
			<transition name="fade" class="editable-field-failure-check">
				<i class="fa fa-fw fa-times fa-lg red" title="Failure" aria-label="Failure" rel="#" style="" v-if="failureIconShowing"></i>
			</transition>
		</div>
		<lb-inline-edit-short-text v-else-if="showInlineTextEditor"
								   @change-value="changeValue"
								   @discard-changes="discardChanges"
								   :original-value="value"
								   :width="textBoxWidth"
								   :max-length="editableFieldConfig.maxLength"></lb-inline-edit-short-text>
		<lb-inline-edit-number v-else-if="showInlineNumberEditor"
							   @change-value="changeValue"
							   @discard-changes="discardChanges"
							   :original-value="new Number(value)"
							   :width="textBoxWidth"></lb-inline-edit-number>
		<lb-inline-edit-boolean v-else-if="showInlineBooleanEditor"
								@change-value="changeValue"
								@discard-changes="discardChanges"
								:original-value="value === 'true' ? true : false"
								:true-display-text="trueDisplayText"
								:false-display-text="falseDisplayText"></lb-inline-edit-boolean>
	</div>
	<div v-else>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			editableFieldType: String,
			entityId: Number,
			value: String,
			editModeOnly: {
				type: Boolean,
				default: true
			},
			popupMaxWidth: {
				type: Number,
				default: 700
			},
			popupMinWidth: {
				type: Number,
				default: 600
			},
			trueDisplayText: {
				type: String,
				default: 'Yes'
			},
			falseDisplayText: {
				type: String,
				default: 'No'
			},
			textBoxWidth: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {
				editing: false,
				globalEditMode: false,
				confirmationIconShowing: false,
				failureIconShowing: false
			}
		},
		computed: {
			editableFieldConfig() {
				return LB.EditableFields.Config[this.editableFieldType];
			},
			inputType() {
				return this.editableFieldConfig.inputType;
			},
			showInlineTextEditor() {
				return this.editing && this.inputType === LB.Enums.InputTypeEnum.ShortText;
			},
			showInlineNumberEditor() {
				return this.editing && this.inputType === LB.Enums.InputTypeEnum.IntegerValue;
			},
			showInlineBooleanEditor() {
				return this.editing && this.inputType === LB.Enums.InputTypeEnum.Boolean;
			},
			siteEditModeIsOn() {
				return this.globalEditMode;
			},
			hasRelevantPermission() {
				return LB.CurrentUser.Context.hasPermission(this.editableFieldConfig.permissionToEdit);
			},
			showEditModeClass() {
				return this.hasRelevantPermission && (this.siteEditModeIsOn || this.editing);
			},
			userCanEdit() {
				return this.hasRelevantPermission && (!this.editModeOnly || this.siteEditModeIsOn);
			},
			hasSpecialEditModeDisplay() {
				return !!(this.$slots['edit-mode-only']) || !!(this.$scopedSlots['edit-mode-only']);
			}
		},
		template: '#editable-field-template',
		methods: {
			turnOnEditMode($event) {
				if (this.userCanEdit) {
					$event.stopPropagation();

					if (this.editableFieldConfig.inputType === LB.Enums.InputTypeEnum.ShortText ||
						this.editableFieldConfig.inputType === LB.Enums.InputTypeEnum.IntegerValue ||
						this.editableFieldConfig.inputType === LB.Enums.InputTypeEnum.Boolean) {
						this.editing = !this.editing;
					} else {
						this.showModalPopup();
					}
				}
			},
			changeValue(newValue) {
				var self = this;

				if (self.editableFieldConfig.required && !newValue.trim()) {
					Vue.nextTick(function () {
						self.failureIconShowing = true;
						Vue.nextTick(function () {
							self.failureIconShowing = false;
						});
					});
				} else {
					this.currentValue = newValue;
					this.$emit('input', `${newValue}`);
					this.$emit('change-value', `${newValue}`);
					this.editing = false;
					this.saveChangesToServer();

					Vue.nextTick(function () {
						self.confirmationIconShowing = true;
						Vue.nextTick(function () {
							self.confirmationIconShowing = false;
						});
					});
				}
			},
			saveChangesToServer() {
				window.axios.post('/EditableField/ChangeValue',
					{
						value: this.currentValue,
						fieldToEdit: this.editableFieldType,
						entityId: this.entityId
					});
			},
			discardChanges() {
				this.editing = false;
			},
			toggleGlobalEditMode(value) {

				this.globalEditMode = value;
			},
			showModalPopup() {
				var self = this;
				const _popup = new ModalPopup(
					self.editableFieldType,
					{
						title: self.editableFieldConfig.description,
						ajaxUrl:
							"/EditableField/ShowEditorFor?fieldToEdit={fieldEnum}&maxWidth={maxWidth}&entityId={entityId}",
						maxWidth: self.popupMaxWidth,
						minWidth: self.popupMinWidth,
						ajaxSuccessBehavior: "CLOSE",
						ajaxSuccessCallback: function (result) {
							self.$emit('input', result);
							self.$emit('change-value', result);
						},
						ajaxFailureCallback: LB_ShowAjaxErrorsInPopup,
						onSubmit: function (modal) {
							var form = modal.popupDiv.find('form');
							// clear out any lingering validation summary items
							$("#errorSummary .validation-summary-errors ul").empty();
							$("#errorSummary .validation-summary-errors").hide();

							LB.UI.setupClientSideValidation($(form));
							var valid = LB.UI.doClientSideValidation($(form));
							if (valid) {
								$(form).submit();
							}
						}
					});
				_popup.show({
					entityId: self.entityId,
					fieldEnum: self.editableFieldType,
					maxWidth: self.popupMaxWidth
				});
			}
		},
		mounted() {
			this.globalEditMode = getCookie('editmodetoggle') === "on";
			EditModeVueListeners.push(this.toggleGlobalEditMode);
		}
	}
</script>
<style type="text/css">
	.fade-leave-active {
		transition: opacity 1s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}
</style>