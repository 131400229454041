var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lb-input-frame", {
    attrs: {
      label: _vm.label,
      required: _vm.required,
      "tooltip-title": _vm.tooltipTitle,
      "tooltip-body": _vm.tooltipBody
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(slotProps) {
          return [
            _c("input", {
              ref: "checkboxValue",
              attrs: {
                type: "checkbox",
                "aria-labelledby": slotProps.labelledBy,
                id: slotProps.id
              },
              domProps: { value: _vm.value, checked: _vm.value },
              on: { input: _vm.updateValue }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }