import vue from 'vue';
import router from './LB.Vue.Router.js';

(function () {
	/*
	 * Taken from https://codeburst.io/shared-state-and-routing-in-vue-js-7bfea06e44ec
	 */
	const _listeners = [];

	window.addEventListener('hashchange', () => {
		_listeners.forEach(l => l.callback());
	});

	return vue.mixin({
		beforeCreate() {
			this.$router = router;
			if (this.$options.routeChanged) {
				_listeners.push({
					component: this,
					callback: this.$options.routeChanged.bind(this)
				});
			}
		},
		destroyed() {
			if (this.$options.routeChanged) {
				const _index = listeners.findIndex(l => l.component == this);
				if (_index >= 0) {
					_listeners.splice(_index, 1);
				}
			}
		}
	});
})();