<template>
	<lb-input-frame :label="label"
					:required="required"
					:tooltip-title="tooltipTitle"
					:tooltip-body="tooltipBody"
					tooltip-class="tooltip-for-rte"
					ref="inputFrame">
		<template v-slot:default="slotProps">
			<vue-ckeditor v-model="inputValue" :config="config" :aria-labelledby="slotProps.labelledBy" :id="slotProps.id" :class="{'rte-for-tooltip': hasTooltip}"></vue-ckeditor>
		</template>
	</lb-input-frame>
</template>

<script>

	import ckEditor from 'ckeditor4-vue';

	export default {
		props: {
			label: String,
			value: String,
			required: Boolean,
			tooltipTitle: String,
			tooltipBody: String
		},
		components: {
			'vue-ckeditor': ckEditor.component
		},
		watch: {
			inputValue(newInputValue) {
				this.$emit('input', newInputValue);
			},
			value(newValue) {
				this.inputValue = newValue;
			}
		},
		data() {
			return {
				inputValue: this.value,
				config: {
					removeDialogTabs: 'image:advanced;link:advanced',
					resize_enabled: true,
					toolbarStartupExpanded: true,
					toolbarCanCollapse: true,
					allowedContent: true,
					basicEntities: false,
					entities: true,
					toolbar: [
						['Font', 'FontSize', 'TextColor', '-', 'Bold', 'Italic', 'Underline', 'Strike', '-', 'Subscript', 'Superscript'],
						'/',
						['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Scayt', '-', 'Undo', 'Redo', '-', 'RemoveFormat'],
						'/',
						['Link', 'Unlink', 'Anchor', '-', 'Image', 'HorizontalRule', 'SpecialChar', '-', 'Source', '-', 'Maximize']
					]
				}
			}
		},
		computed: {
			fieldName() {
				return this.label && this.label.replace(/\s/gi, '-').toLowerCase();
			},
			hasTooltip() {
				return this.tooltipTitle || this.tooltipBody;
			}
		},
		template: '#rich-text-input-template',

	}
</script>