import Vue from 'vue';
import _ from 'lodash';
import _regenerator from 'regenerator-runtime'; // allows use of 'async' keyword.

export function loadGlobals() {
	const _requireComponent = require.context(
		// Look for files in the current directory
		'./GlobalComponents',
		// Look in subdirectories
		true,
		// Only include .js files
		/[\w-]+\.(js|vue)$/i
	);

	// For each matching file name...
	_requireComponent.keys().forEach((fileName) => {
		// Get the component config
		const _componentConfig = _requireComponent(fileName);

		// Component names are kebab-case. Directory / file names are PascalCase
		// We can infer the name of the component from the directory / file name
		// and then convert it from PascalCase to kebab-case

		//split on '/'
		const _parts = fileName.split('/');

		let _componentName = null;
		// Component directories will start with 'Lb'
		// On the off chance that it grabs a file name instead of a directory
		// we need to remove the file extenstion
		// This should also work for vue files that begin with an 'Lb' prefix
		let _pascalName = _.find(_parts, p => /^Lb/.test(p));
		if (_pascalName) {
			_pascalName = _pascalName.replace(/\.\w+$/, '');
			_componentName = _.kebabCase(_pascalName);
		}

		// If the file doesn't match one of the above patterns,
		// we want to check for .vue files beginning with an 'lb-' prefix.
		if (!_componentName) {
			let _kebabName = _.find(_parts, p => /^lb-.*.vue/.test(p));
			if (_kebabName) {
				_kebabName = _kebabName.replace(/\.\w+$/, '');
				_componentName = _kebabName;
			}
		}

		// Globally register the component
		if (_componentName) {
			Vue.component(_componentName, _componentConfig.default || _componentConfig);
		}
	});

};

