var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "collapsible-container",
      attrs: {
        "data-fileid":
          "Content_Source_VueCommon_GlobalComponents_Containers_lb-collapsible-container"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "collapsible-container-collapse-bar",
          class: _vm.classes,
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleContainer($event)
            }
          }
        },
        [
          _c("div", { staticClass: "collapsible-container-expand-icon" }, [
            _vm.canToggle
              ? _c("i", {
                  staticClass: "fa fa-lg",
                  class: _vm.isCollapsed ? "fa-caret-right" : "fa-caret-down"
                })
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "collapsible-container-content" },
        [
          _vm.titleContentExists
            ? _c(
                "div",
                {
                  staticClass: "collapsible-container-title-bar",
                  class: { pointer: _vm.canToggle },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleContainer($event)
                    }
                  }
                },
                [_vm._t("title")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.collapsibleContentExists
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCollapsedContent,
                      expression: "showCollapsedContent"
                    }
                  ],
                  staticClass: "collapsible-container-hideable-content"
                },
                [_vm._t("collapse-content")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("footer")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }