// Content/_Source/_VueCommon/GlobalComponents/LbAutoCompleteField/Index.js

require('webpack-jquery-ui/autocomplete');

export default {
	props: {
		autoCompleteUrl: String,
		autoCompleteLimit: {
			type: Number,
			default: 20
		},
		searchDelay: {
			type: Number,
			default: 500
		},
		minimumCharacters: {
			type: Number,
			default: 0
		},
		value: String,
		inputClass: String,
		label: String,
		required: Boolean,
		tooltipTitle: String,
		tooltipBody: String,
		tooltipClass: String,
		inputId: {
			type: String,
			default: '',
			required: false
		},
		alignLabelLeft: {
			type: Boolean,
			default: false
		}
	},
	template: '#auto-complete-field-template',
	methods: {
		initializeAutoComplete() {
			var self = this;
			this.$nextTick(function () {
				var jqEle = $('.jq-autocomplete-field', self.$el);
				jqEle.autocomplete({
					minLength: self.minimumCharacters,
					source: function(request, response) {
						$.ajax({
							url: self.autoCompleteUrl,
							dataType: 'json',
							data: {
								q: request.term,
								limit: self.autoCompleteLimit
							},
							success: function(data) {
								if (!data.length) {
									var result = [{ label: ":NORESULTS:", value: response.term }];
									response(result);
								} else {
									response(data);
								}
							}
						});
					},
					delay: self.searchDelay,
					position: {
						my: "left top",
						at: "left bottom",
						collision: "flip"
					},
					select: function (event, ui) {
						event.preventDefault(); // <--- Prevent the value from being inserted.
						$(this).val(ui.item.label);
						self.$emit('input', ui.item.value);
					},
					change: function (event, ui) {
						if (ui.item === null) {
							self.$emit('input', null);
						}
					},
					create: function () {
						$(this).data('ui-autocomplete')._renderItem = function (ul, item) {
							if (item.label === ':NORESULTS:') {
								return $("<li>")
									.addClass("no-results-placeholder")
									.append(item.label)
									.appendTo(ul);
							} else {
								return $("<li>")
									.append(item.label)
									.appendTo(ul);
							}
						};

						$(this).data('ui-autocomplete')._renderMenu = function (ul, items) {
							var that = this;
							var enteredText = jqEle.val();
							var itemText = null;
							$.each(items,
								function (index, item) {
									that._renderItemData(ul, item);
								});

							if (enteredText) {
								if (items[0].label === ':NORESULTS:') {
									itemText = 'No matches found for <strong>' + enteredText + '</strong>.';
									$(ul).append(
										'<li class="ui-autocomplete-footer" aria-label="' + itemText + '">' + itemText + '</li>');
								} else if (items.length >= 20) {
									itemText = 'Showing the first 20 results for <strong>' +
										enteredText +
										'</strong>. Type to refine search.';
									$(ul).append(
										'<li class="ui-autocomplete-footer" aria-label="' + itemText + '">' + itemText + '</li>');
								} else {
									itemText = items.length + ' matches found for <strong>' + enteredText + '</strong>.';
									$(ul).append(
										'<li class="ui-autocomplete-footer" aria-label="' + itemText + '">' + itemText + '</li>');
								}
							} else {
								if (items[0].label === ':NORESULTS:') {
									itemText = 'No results';
									$(ul).append(
										'<li class="ui-autocomplete-footer" aria-label="' + itemText + '">' + itemText + '</li>');
								} else if (items.length >= 20) {
									itemText = 'Showing the first 20 results. Type to refine search.';
									$(ul).append(
										'<li class="ui-autocomplete-footer" aria-label="' + itemText + '">' + itemText + '</li>');
								}
							}
						};
					}
				}).focus(function () {
					$(this).select();
					if ($(this).val() === '') {
						$(this).autocomplete("search");
					}
				});;
			});
		}
	},
	updated: function() {
		if (!$('.jq-autocomplete-field', this.$el).hasClass('ui-autocomplete-input')) {
			this.initializeAutoComplete();
		}
	},
	mounted: function() {
		this.initializeAutoComplete();
	},
	computed: {
		key() {
			return `${this.autoCompleteLimit}${this.searchDelay}${this.minimumCharacters}`;
		}
	}
};