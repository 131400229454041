<template>
	<a @click.stop="clickOnceClick" href="JavaScript: void(0);" class="clickOnce" :class="activeCssClass" :style="buttonStyle" :title="toolTipText">{{ linkText }}</a>
</template>
<script>
	export default {
		props: {
			linkText: String,
			targetUrl: String,
			showLoadingMessage: {
				type: Boolean,
				default: true
			},
			loadingMessage: {
				type: String,
				default: 'Loading...'
			},
			buttonStyle: String,
			activeCssClass: String,
			toolTipText: String
		},
		methods: {
			clickOnceClick() {
				let _self = this;
				ClickOnceClicked(function () {
					document.location.href = `${_self.targetUrl}`
				}, (_self.showLoadingMessage && this.loadingMessage), this.loadingMessage);
				return false;
			}
		}
	};
</script>
