var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("lb-input-frame", {
    ref: "inputFrame",
    attrs: {
      label: _vm.label,
      required: _vm.required,
      "tooltip-title": _vm.tooltipTitle,
      "tooltip-body": _vm.tooltipBody,
      "tooltip-class": "tooltip-for-rte"
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(slotProps) {
          return [
            _c("vue-ckeditor", {
              class: { "rte-for-tooltip": _vm.hasTooltip },
              attrs: {
                config: _vm.config,
                "aria-labelledby": slotProps.labelledBy,
                id: slotProps.id
              },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }