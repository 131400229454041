<template>
	<div>
		<div v-if="siteEditModeIsOn" :title="linkTitle" :aria-label="linkTitle">
			{{message}} <i @click.stop.prevent="openLink()" class="pointer">{{editLink}}</i>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			editLink: String,
			message: String,
			linkTitle: String
		},
		data() {
			return {
				globalEditMode: false,
			}
		},
		computed: {
			siteEditModeIsOn() {
				return this.globalEditMode;
			}
		},
		methods: {
			toggleGlobalEditMode(value) {
				this.globalEditMode = value;
			},
			openLink() {
				window.open(this.editLink);
			}
		},
		mounted() {
			this.globalEditMode = getCookie('editmodetoggle') === "on";
			EditModeVueListeners.push(this.toggleGlobalEditMode);
		}
	}
</script>