<template>
    <span class="loading-indicator">
        <i class="fa fa-lg fa-spinner fa-spin"></i>
        <span><slot>Loading!</slot></span>
    </span>
</template>

<script>
	export default {
		props: {
			// Actually none
		}
	}
</script>