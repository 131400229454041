export default {
	props: {
		label: String,
		instructions: String,
		required: Boolean,
		tooltipTitle: String,
		tooltipBody: String,
		tooltipClass: String,
		inputId: {
			type: String,
			default: '',
			required: false
		},
		alignLabelLeft: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		labelDivId() {
			return this.valueId + '-label';
		},
		valueId() {
			const _label = this.label;
			return this.inputId ||
				(_label && `${_label.replace(/\s/gi, '-').toLowerCase()}${Math.ceil(Math.random() * 1000)}`);
		}
	},
	template: '#input-frame-template'
};