// Content/_Source/_VueCommon/GlobalComponents/LbTestInput/Index.js

export default {
	props: {
		label: String,
		value: String,
		required: Boolean,
		inputClass: String,
		tooltipTitle: String,
		tooltipBody: String,
		maxLength: Number,
		readOnly: Boolean
	},
	methods: {
		updateValue() {
			this.$emit('input', this.$refs.text.value);
		}
	},
	template: '#text-input-template'
};