<template>
	<div v-show="showModal">
		<slot></slot>
	</div>
</template>

<script>
	let _modal = null;

	export default {
		props: {
			modalId: {
				type: String,
				required: true
			},
			bodyId: {
				type: String,
				required: true
			},
			title: String,
			maxWidth: Number,
			minWidth: Number,
			showModal: {
				type: Boolean,
				required: true
			}
		},
		methods: {
			openModal() {
				if (_modal) {
					_modal.setTitle(this.title);
					_modal.show();					
					var errorDiv = _modal.popupDiv.find(".validation-summary-errors").first();
					errorDiv.hide();

				}
			},
			closeModal() {
				if (_modal) {
					_modal.hide();
				}
			},
			onHideCallback() {
				this.$emit('onTaskModalHide');
			}
		},
		mounted() {
			_modal = new TaskModal(this.modalId, {
				title: this.title ?? null,
				bodyId: this.bodyId,
				onHideCallback: this.onHideCallback,
				maxWidth: this.maxWidth ?? null,
				minWidth: this.minWidth ?? null
			});
		},
		watch: {
			showModal() {
				let _self = this;
				if (_self.showModal) {
					_self.$nextTick().then(function () {
						_self.openModal()
					});
					_self.$nextTick().then(function () {
						ModalPopup_ResizeActivePopup();
					});
				} else {
					_self.$nextTick().then(function () {
						_self.closeModal();
					})
				}
			}
		}
	}

</script>