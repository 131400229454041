/*
 * Taken from https://codeburst.io/shared-state-and-routing-in-vue-js-7bfea06e44ec
 */

import vue from 'vue';

const _router = {
	get route() {
		const href = window.location.href;
		const index = href.indexOf('#');
		if (index >= 0)
			return href.slice(index + 1);
		return '';
	},
	push(route) {
		window.location.hash = route;
		window.scrollTo(0, 0);
	}
};

export default _router;