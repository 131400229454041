// Content/_Source/_VueCommon/ApiClient/Index.js

/*
This file is intended to be used to call api methods from a Vuex store and requires a state to be passed in. It sets the array state.validationErrors or clears it.
*/

import axios from 'axios';
import "regenerator-runtime/runtime";

// This is also set in the Constants class and must be kept in sync!
const _LOGIN_PAGE_MARKER_TEXT = "HS::LB::ModalPopup::LoginPageMarkerText";

const _axiosClient = axios.create();

const _formatErrorsAsHtml = function(errors) {
	var message = "";

	for (var i = 0; i < errors.length; i++) {
		message += "<li>" + errors[i].Text + "</li>";
	}

	//message += "</ul>";

	return message;
}

const _showErrorsInValidationSummary = function(errors) {
	var errorDiv = $(".validation-summary-errors").first();

	errorDiv.hide();
	errorDiv.children("ul").remove();
	errorDiv.append("<ul>" + _formatErrorsAsHtml(errors) + "</ul>");
	errorDiv.show();

	LB.UI.scrollTo(".validation-summary-errors");
}

const _apiCall = async function(url, payload, successCallback, errorCallback, method) {
	try {
		let _response = {};
		switch (method) {
		case 'post':
			_response = await _axiosClient.post(url, payload);
			break;
		default:
			_response = await _axiosClient.get(url, payload);
			break;
		}
		//console.debug(JSON.stringify(_response, null, 2));

		if (_response.data &&
			typeof _response.data !== 'object' &&
			_response.data.indexOf(_LOGIN_PAGE_MARKER_TEXT) > 0) {
			const _returnUrl = escape(`${window.location.pathname}${window.location.hash}`);
			window.location.href = `/Account/Login?ReturnUrl=${_returnUrl}`;
			return {};
		}

		var errorDiv = $(".validation-summary-errors").first();
		errorDiv.hide();

		successCallback && successCallback(_response);
		return _response;
	} catch (error) {
		console.debug(JSON.stringify(error, null, 2));
		if (error.response.status === 500) {
			const _errorMessage = error && error.response && error.response.data && error.response.data.description
				? response.data.description
				: "An error occurred, please reload the page or contact your System Administrator";
			LB.Growl.failure(_errorMessage, { sticky: true });
		}
		if (error.response.status === 400) {
			if (error && error.response && error.response.data && error.response.data.length) {
				_showErrorsInValidationSummary(error.response.data);
				errorCallback && errorCallback(error);
				return;
			}
		}
		errorCallback && errorCallback(error);
		throw(error);
	}
};

const _post = async function(url, payload, successCallback, errorCallback) {
	return _apiCall(url, payload, successCallback, errorCallback, 'post');
};

const _get = async function(url, payload, successCallback, errorCallback) {
	return _apiCall(url, payload, successCallback, errorCallback, 'get');
};

const _showSingleError = async function (error) {
	const _data = error && error.response && error.response.data;
	if (_data && _data.length) {
		let _text = Array.isArray(_data) ? _data[0].Text : _data;
		LB.Growl.failure(_text);
	} else {
		throw (error);
	}
}

export default {
	post: _post,
	get: _get,
	showSingleError: _showSingleError
};