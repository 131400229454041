var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "clickOnce",
      class: _vm.activeCssClass,
      style: _vm.buttonStyle,
      attrs: { href: "JavaScript: void(0);", title: _vm.toolTipText },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clickOnceClick($event)
        }
      }
    },
    [_vm._v(_vm._s(_vm.linkText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }