var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "inline-short-text-editor editable-field-input",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentValue,
            expression: "currentValue"
          }
        ],
        style: "width: " + _vm.width + "px;",
        attrs: {
          type: "text",
          "max-length": _vm.maxLength,
          "aria-label": _vm.ariaLabel
        },
        domProps: { value: _vm.currentValue },
        on: {
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.changeValue()
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.discardChanges()
            }
          ],
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentValue = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-fw fa-check fa-lg green",
        attrs: {
          title: "Save Changes",
          "aria-label": "Save Changes",
          rel: "#"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.changeValue()
          }
        }
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-fw fa-undo fa-lg red",
        attrs: {
          title: "Discard Changes",
          "aria-label": "Discard Changes",
          rel: "#"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.discardChanges()
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }