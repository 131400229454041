export default {
	props: {
		label: String,
		value: String,
		required: Boolean,
		tooltipTitle: String,
		tooltipBody: String,
		cols: Number,
		rows: Number,
		readOnly: Boolean
	},
	methods: {
		updateValue() {
			this.$emit('input', this.$refs.textArea.value);
		}
	},
	template: '#text-area-input-template'
};
