<template>
	<div class="inline-number-editor  editable-field-input" @click.stop>
		<input type="number" :style="`width: ${width}px;`" :aria-label="ariaLabel" v-model="currentValue" @keydown.enter="changeValue()" @keydown.esc="discardChanges()" />
		<i class="fa fa-fw fa-check fa-lg green" title="Save Changes" aria-label="Save Changes" rel="#" style="" @click.stop.prevent="changeValue()"></i>
		<i class="fa fa-fw fa-undo fa-lg red" title="Discard Changes" aria-label="Discard Changes" rel="#" style="" @click.stop.prevent="discardChanges()"></i>
	</div>
</template>
<script>
	export default {
		props: {
			ariaLabel: String,
			originalValue: Number,
			width: Number
		},
		data() {
			return {
				currentValue: this.originalValue
			}
		},
		template: '#editable-field-number-template',
		methods: {
			changeValue() {
				this.$emit('change-value', this.currentValue);
			},
			discardChanges($event) {
				this.$emit('discard-changes', $event);
			}
		}
	}
</script>