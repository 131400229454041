var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.siteEditModeIsOn
    ? _c(
        "div",
        {
          staticClass: "editable-field",
          class: {
            "edit-mode-on": _vm.showEditModeClass,
            pointer: _vm.userCanEdit
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.turnOnEditMode($event)
            }
          }
        },
        [
          !_vm.editing
            ? _c(
                "div",
                { staticClass: "editable-field-display-mode" },
                [
                  _vm.hasSpecialEditModeDisplay
                    ? _vm._t("edit-mode-only")
                    : _vm._t("default"),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      staticClass: "editable-field-confirmation-check",
                      attrs: { name: "fade" }
                    },
                    [
                      _vm.confirmationIconShowing
                        ? _c("i", {
                            staticClass: "fa fa-fw fa-check fa-lg green",
                            attrs: {
                              title: "Success",
                              "aria-label": "Success",
                              rel: "#"
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      staticClass: "editable-field-failure-check",
                      attrs: { name: "fade" }
                    },
                    [
                      _vm.failureIconShowing
                        ? _c("i", {
                            staticClass: "fa fa-fw fa-times fa-lg red",
                            attrs: {
                              title: "Failure",
                              "aria-label": "Failure",
                              rel: "#"
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ],
                2
              )
            : _vm.showInlineTextEditor
            ? _c("lb-inline-edit-short-text", {
                attrs: {
                  "original-value": _vm.value,
                  width: _vm.textBoxWidth,
                  "max-length": _vm.editableFieldConfig.maxLength
                },
                on: {
                  "change-value": _vm.changeValue,
                  "discard-changes": _vm.discardChanges
                }
              })
            : _vm.showInlineNumberEditor
            ? _c("lb-inline-edit-number", {
                attrs: {
                  "original-value": new Number(_vm.value),
                  width: _vm.textBoxWidth
                },
                on: {
                  "change-value": _vm.changeValue,
                  "discard-changes": _vm.discardChanges
                }
              })
            : _vm.showInlineBooleanEditor
            ? _c("lb-inline-edit-boolean", {
                attrs: {
                  "original-value": _vm.value === "true" ? true : false,
                  "true-display-text": _vm.trueDisplayText,
                  "false-display-text": _vm.falseDisplayText
                },
                on: {
                  "change-value": _vm.changeValue,
                  "discard-changes": _vm.discardChanges
                }
              })
            : _vm._e()
        ],
        1
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }