<template>
	<div class="inline-short-text-editor editable-field-input" @click.stop>
		<input type="text" :max-length="maxLength" :style="`width: ${width}px;`" :aria-label="ariaLabel" v-model="currentValue" @keydown.enter="changeValue()" @keydown.esc="discardChanges()" />&nbsp;<i class="fa fa-fw fa-check fa-lg green" title="Save Changes" aria-label="Save Changes" rel="#" style="" @click.stop.prevent="changeValue()"></i>&nbsp;<i class="fa fa-fw fa-undo fa-lg red" title="Discard Changes" aria-label="Discard Changes" rel="#" style="" @click.stop.prevent="discardChanges()"></i>
	</div>
</template>

<script>

	export default {
		props: {
			ariaLabel: String,
			originalValue: String,
			width: Number,
			maxLength: {
				type: Number
			}
		},
		data() {
			return {
				currentValue: this.originalValue
			}
		},
		template: '#editable-field-short-text-template',
		methods: {
			changeValue() {
				this.$emit('change-value', this.currentValue);
			},
			discardChanges($event) {
				this.$emit('discard-changes', $event);
			}
		}

	}

</script>