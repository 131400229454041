<template>
	<div class="collapsible-container" data-fileid="Content_Source_VueCommon_GlobalComponents_Containers_lb-collapsible-container">
		<div v-on:click.prevent="toggleContainer" class="collapsible-container-collapse-bar" v-bind:class="classes">
			<div class="collapsible-container-expand-icon">
				<i v-if="canToggle" v-bind:class="isCollapsed ? 'fa-caret-right' : 'fa-caret-down'" class="fa fa-lg"></i>
			</div>
		</div>
		<div class="collapsible-container-content">
			<div v-on:click.prevent="toggleContainer" class="collapsible-container-title-bar" v-if="titleContentExists" v-bind:class="{ 'pointer': canToggle }">
				<slot name="title"></slot>
			</div>
			<slot></slot>
			<div v-show="showCollapsedContent" v-if="collapsibleContentExists" class="collapsible-container-hideable-content">
				<slot name="collapse-content"></slot>
			</div>
			<slot name="footer"></slot>
		</div>
	</div>

</template>

<script>
	export default {
		props: {
			canToggle: Boolean,
			barColorClass: {
				type: String,
				required: false,
				default: ''
			},
			isCollapsed: Boolean
		},
		computed: {
			showToggleButton() {
				return this.canToggle && this.collapsibleContentExists;
			},
			classes: function () {
				var classString = "";

				if (this.canToggle) {
					classString += "pointer ";
				}

				classString += this.barColorClass;

				return classString;
			},
			titleContentExists: function () {
				return this.$slots.title;
			},
			collapsibleContentExists: function () {
				return this.$slots['collapse-content'];
			},
			showCollapsedContent: function () {
				return !this.isCollapsed;
			}
		},
		methods: {
			toggleContainer() {
				if (this.canToggle) {
					this.$emit('toggle-container');
				}
			}

		}
	}
</script>