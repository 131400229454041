<template>
	<div class="inline-boolean-editor  editable-field-input" @click.stop>
		<label class="true-option">
			{{ trueDisplayText }}
			<input type="radio" :name="fieldName" ref="radio" :value="true" v-model="currentValue" />
		</label>
		<label class="false-option">
			{{ falseDisplayText }}
			<input type="radio" :name="fieldName" ref="radio" :value="false" v-model="currentValue" />
		</label>
		<i class="fa fa-fw fa-check fa-lg green" title="Save Changes" aria-label="Save Changes" rel="#" style="" @click.stop.prevent="changeValue()"></i>
		<i class="fa fa-fw fa-undo fa-lg red" title="Discard Changes" aria-label="Discard Changes" rel="#" style="" @click.stop.prevent="discardChanges()"></i>
	</div>
</template>
<script>
	export default {
		props: {
			ariaLabel: String,
			originalValue: Boolean,
			trueDisplayText: String,
			falseDisplayText: String
		},
		data() {
			return {
				currentValue: this.originalValue
			}
		},
		computed: {
			fieldName() {
				function guid() {
					function _p8(s) {
						var p = (Math.random().toString(16) + "000000000").substr(2, 8);
						return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
					}
					return _p8() + _p8(true) + _p8(true) + _p8();
				}

				return guid();
			}
		},
		template: '#editable-field-boolean-template',
		methods: {
			changeValue() {
				this.$emit('change-value', this.currentValue);
			},
			discardChanges($event) {
				this.$emit('discard-changes', $event);
			}
		}
	}
</script>