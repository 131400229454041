<template>
	<lb-input-frame :label="label"
					:required="required"
					:tooltip-title="tooltipTitle"
					:tooltip-body="tooltipBody">
		<template v-slot:default="slotProps">
			<input type="checkbox" ref="checkboxValue" :value="value" @input="updateValue" :checked="value" :aria-labelledby="slotProps.labelledBy" :id="slotProps.id" />
			<!--<input type="checkbox" ref="checkboxValue" v-model="value" :checked="value" :aria-labelledby="slotProps.labelledBy" :id="slotProps.id" />-->
		</template>
	</lb-input-frame>
</template>

<script>
	export default {
	props: {
		label: String,
			value: Boolean,
			required: Boolean,
			tooltipTitle: String,
			tooltipBody: String
	},
	methods: {
		updateValue($event) {
			this.$emit('input', this.$refs.checkboxValue.checked);
		}
	}
}
</script>