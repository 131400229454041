var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.siteEditModeIsOn
      ? _c(
          "div",
          { attrs: { title: _vm.linkTitle, "aria-label": _vm.linkTitle } },
          [
            _vm._v("\n\t\t" + _vm._s(_vm.message) + " "),
            _c(
              "i",
              {
                staticClass: "pointer",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.openLink()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.editLink))]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }