<template>
	<lb-input-frame :label="label"
					:required="required"
					:tooltip-title="tooltipTitle"
					:tooltip-body="tooltipBody"
					tooltip-class="tooltip-for-rte"
					ref="inputFrame">
		<template v-slot:default="slotProps">
			<vue-ace-editor v-model="inputValue" :lang="lang" :theme="theme" :height="height" :options="editorOptions" @init="editorInit" :class="{'rte-for-tooltip': hasTooltip}"></vue-ace-editor>
		</template>
	</lb-input-frame>
</template>

<script>

	import _ from 'lodash';
	import aceEditor from 'vue2-ace-editor';
	import ace from 'brace';

	export default {
		props: {
			label: String,
			value: String,
			required: Boolean,
			tooltipTitle: String,
			tooltipBody: String,
			lang: String,
			theme: String,
			height: {
				type: Number,
				default: 500
			},
			readOnly: Boolean
		},
		components: {
			'vue-ace-editor': aceEditor
		},
		watch: {
			inputValue(newInputValue) {
				this.$emit('input', newInputValue);
			},
			value(newValue) {
				this.inputValue = newValue;
			}
		},
		data() {
			return {
				inputValue: this.value,
			}
		},
		computed: {
			editorOptions() {
				return {
					showGutter: true,
					enableBasicAutocompletion: true,
					enableSnippets: true,
					enableLiveAutocompletion: true,
					readOnly: this.readOnly
				};				
			},
			hasTooltip() {
				return this.tooltipTitle || this.tooltipBody;
			}
		},
		methods: {
			editorInit: function () {
				require('brace/mode/html');
				require('brace/mode/scss');
				require('brace/mode/liquid');
				require('brace/mode/json');
				require('brace/theme/chrome');
				require('brace/theme/monokai');
			}
		}

	}
</script>