require('_Vendor/Tooltipster/js/tooltipster.bundle.js');
//import tooltipster from 'tooltipster';

// These were here for legacy purposes. We're not using them anymore
// Once the LearningPlanTaskGroupTestHarness has been migrate, and 
// the SetTooltipAnimationDuration method in TestHarnessDataAction
// has been deleted we can remove them.
//import { registerNamespace } from '_LbCommon/RegisterNamespace.js';
//registerNamespace('LB.Vue.Components.LbTooltipster');
//LB.Vue.Components.LbTooltipster.fadeIn = 3000; //This is the tooltipster default, that's why 350 is the value chosen as our default.

export default {

	props: {
		body: String,
		iconClassName: {
			type: String,
			default: 'fa-info-circle'
		},
		containerClass: String,
		side: {
			type: Array,
			default: function() {
				return ['left', 'bottom', 'right', 'top'];
			}
		},
		maxWidth: {
			type: Number,
			default: 285
		},
		title: String,
		animationDuration: {
			type: Number,
			default: 350
		}
	},
	template: '#tooltip-template',
	updated: function() {
		if (!$('.jq-tooltipster', this.$el).hasClass('tooltipstered')) {
			this.initializeTooltip();
		}
	},
	mounted: function() {
		this.initializeTooltip();
	},
	methods: {
		initializeTooltip() {
			this.$nextTick(function() {
				$('.jq-tooltipster', this.$el).tooltipster({
					side: this.side,
					animationDuration: this.animationDuration,
					maxWidth: this.maxWidth,
					interactive: true
				});
			});
		}
	},
	computed: {
		key() {
			return `${this.body}${this.iconClassName}${this.containerClass}${JSON.stringify(this.side)}${this.maxWidth
				}${this.title}${this.animationDuration}`;
		},
		// Don't really care what the id of the tooltip content is, but we need it to
		// unique for each tooltip on the page
		tooltipId: function() {
			var length = 9;
			var id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
			return id;
		},
		tooltipValue: function() {
			if (this.title) {
				return '<h2>' + this.title + '</h2>' + this.body;
			}

			return this.body;
		}
	}
};
